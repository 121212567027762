var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "ultimate-gear",
      "title": _vm.$t('modalAchievement'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('achievement-avatar', {
    staticClass: "avatar"
  })], 1), _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('gearAchievement')))]), _c('br'), _c('table', {
    staticClass: "multi-achievement"
  }, [_c('tr', [_vm.user.achievements.ultimateGearSets.healer ? _c('td', {
    staticClass: "multi-achievement"
  }, [_c('div', {
    staticClass: "achievement-ultimate-healer2x multi-achievement"
  }), _vm._v(" " + _vm._s(_vm.$t('healer')) + " ")]) : _vm._e(), _vm.user.achievements.ultimateGearSets.wizard ? _c('td', {
    staticClass: "multi-achievement"
  }, [_c('div', {
    staticClass: "achievement-ultimate-mage2x multi-achievement"
  }), _vm._v(" " + _vm._s(_vm.$t('mage')) + " ")]) : _vm._e(), _vm.user.achievements.ultimateGearSets.rogue ? _c('td', {
    staticClass: "multi-achievement"
  }, [_c('div', {
    staticClass: "achievement-ultimate-rogue2x multi-achievement"
  }), _vm._v(" " + _vm._s(_vm.$t('rogue')) + " ")]) : _vm._e(), _vm.user.achievements.ultimateGearSets.warrior ? _c('td', {
    staticClass: "multi-achievement"
  }, [_c('div', {
    staticClass: "achievement-ultimate-warrior2x multi-achievement"
  }), _vm._v(" " + _vm._s(_vm.$t('warrior')) + " ")]) : _vm._e()])]), _c('br'), !_vm.user.achievements.ultimateGearSets.healer && _vm.user.achievements.ultimateGearSets.wizard && _vm.user.achievements.ultimateGearSets.rogue && _vm.user.achievements.ultimateGearSets.warrior ? _c('div', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('moreGearAchievements'))
    }
  }), _c('br')]) : _vm._e(), _c('Sprite', {
    attrs: {
      "image-name": "shop_armoire"
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('armoireUnlocked'))
    }
  }), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('huzzah')) + " ")])], 1)]), _c('achievement-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }