var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": _vm.canRemove,
      "has-icon": true,
      "notification": _vm.notification,
      "read-after-click": true
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.notification.data.title) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.notification.data.text) + " ")])]), _c('Sprite', {
    staticClass: "mt-3",
    attrs: {
      "slot": "icon",
      "image-name": _vm.notification.data.icon
    },
    slot: "icon"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }