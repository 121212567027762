var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "level-up",
      "size": "sm",
      "title": _vm.title,
      "ok-only": "",
      "ok-title": _vm.$t('onwards'),
      "footer-class": {
        greyed: _vm.displayRewardQuest
      }
    }
  }, [_c('section', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "star-group mirror",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.starGroup)
    }
  }), _c('avatar', {
    staticClass: "avatar",
    attrs: {
      "member": _vm.user
    }
  }), _c('span', {
    staticClass: "star-group",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.starGroup)
    }
  })], 1), _vm._m(0), _vm.displayRewardQuest ? _c('section', {
    staticClass: "greyed"
  }, [_vm._m(1), _c('Sprite', {
    attrs: {
      "image-name": _vm.questClass
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t('levelup')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "your-rewards d-flex"
  }, [_c('span', {
    staticClass: "sparkles",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('yourRewards')))]), _c('span', {
    staticClass: "sparkles mirror",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  })]);

}]

export { render, staticRenderFns }